import Vue from 'vue'
import App from './App.vue'
import '../src/assets/css/all.css'
// import ElementUI from 'element-ui'
import { Button, Select ,Form,FormItem,Input,Message,Loading,Container,Aside,Menu,Submenu,MenuItem,MenuItemGroup,Card,Col,Link,Dropdown,DropdownMenu,DropdownItem,Main,Row,Dialog,Avatar,MessageBox,Collapse,CollapseItem,Table,TableColumn,ButtonGroup,ColorPicker,Tooltip,Option,OptionGroup,Backtop,Icon,Radio,Image,Header,Tabs,TabPane,DatePicker,Pagination,Tag,Divider,Tree,InputNumber} from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2';
import './assets/iconfont/iconfont.css'
import './assets/js/iconfont'
import ssoPlugin from './plugin/ssoPlugin';

Vue.use(ssoPlugin);


Vue.config.productionTip = false

// Vue.use(ElementUI)   // 使用ElementUI
Vue.use(Button).use(Select).use(Form).use(FormItem).use(Input).use(Loading.directive).use(Container).use(Aside).use(Menu).use(Submenu).use(MenuItem).use(MenuItemGroup).use(Card).use(Col).use(Link).use(Dropdown).use(DropdownMenu).use(DropdownItem).use(Main).use(Row).use(Dialog).use(Avatar).use(Collapse).use(CollapseItem).use(Table).use(TableColumn).use(ButtonGroup).use(ColorPicker).use(Tooltip).use(Option).use(OptionGroup).use(Backtop).use(Icon).use(Radio).use(Image).use(Header).use(Tabs).use(TabPane).use(DatePicker).use(Pagination).use(Tag).use(Divider).use(Tree).use(InputNumber) ;
Vue.use(VueRouter)  // 使用VueRouter

Vue.use(VueClipboard);  // 在全局中使用 VueClipboard 插件

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;

Vue.prototype.$http = axios.create({
  headers: {
    Authorization: localStorage.getItem('access_token')
  }
})

new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate(){
    Vue.prototype.$bus = this   //设置全局总线
  }
}).$mount('#app')
