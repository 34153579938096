<template>
  <div id="app" >
      <!--最外层路由的出口：决定展示登录页还是dashboard页面-->
      <router-view></router-view>
  </div>
  
</template>

<script>
import apis from './api'
export default {
  methods:{
    getLoginStatus() {
      apis.getUserLoginStatus().then(
        (res) => {
          console.log(res.data)
        },
        (error) => {
          console.log("发生错误");
        }
      );
    },
  },
  created() {
    // this.getLoginStatus();
  },

  
}
</script>

<style>

</style>